import axios from "../http";
import { BASE } from "../index";
export const URL = BASE.PRO1;

/**
 * 基本费用列表
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeList(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/list`, params);
}

/* 变更基本费用
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeUpdate(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/update`, params);
}

/* 基本费用变更日志
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeLog(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/log`, params);
}

/* 成本录入审核列表
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeSettlementList(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/settlement/list`, params);
}

/* 成本录入表编辑应扣金额
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeSettlementChange(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/settlement/change`, params);
}

/* 成本录入审批
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeSettlementAudit(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/settlement/audit`, params);
}

/* 成本录入取消审批
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeSettlementCancel(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/settlement/cancel`, params);
}

/* 成本录入扣款
 * @param {*} params
 * @returns
 */
export function postLogisticsBasicFeeSettlementDeduct(params = {}) {
  return axios.post(`${URL}/logistics/basic/fee/settlement/deduct`, params);
}

/* 保险录入列表
 * @param {*} params
 * @returns
 */
export function postLogisticsPremiumsSettlementList(params = {}) {
  return axios.post(`${URL}/logistics/premiums/settlement/list`, params);
}

/* 编辑应扣保险金额
 * @param {*} params
 * @returns
 */
export function postLogisticsPremiumsSettlementChange(params = {}) {
  return axios.post(`${URL}/logistics/premiums/settlement/change`, params);
}

/* 保险成本汇总
 * @param {*} params
 * @returns
 */
export function postLogisticsPremiumsSettlementTotal(params = {}) {
  return axios.post(`${URL}/logistics/premiums/settlement/total`, params);
}

/* 保险成本取消审批
 * @param {*} params
 * @returns
 */
export function postLogisticsPremiumsSettlementCancel(params = {}) {
  return axios.post(`${URL}/logistics/premiums/settlement/cancel`, params);
}

/* 保险成本审批
 * @param {*} params
 * @returns
 */
export function postLogisticsPremiumsSettlementAudit(params = {}) {
  return axios.post(`${URL}/logistics/premiums/settlement/audit`, params);
}

/* 保险成本扣款
 * @param {*} params
 * @returns
 */
export function postLogisticsPremiumsSettlementDeduct(params = {}) {
  return axios.post(`${URL}/logistics/premiums/settlement/deduct`, params);
}
