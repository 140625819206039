<template>
  <section class="city-insurance-entry-wrap">
    <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="formData"
      @Refresh="Refresh"
      @search="onHandleSearch"
    >
      <template v-slot:right>
        <el-button
          @click="onHandleExport"
          type="warning"
          icon="el-icon-upload2"
          :disabled="disabledExport || !tableData.length"
          >导出</el-button
        >
        <el-button
          type="info"
          :disabled="!selectIds.length || disabledAudit"
          @click="onBatchAudit"
          >批量审核</el-button
        >
        <el-button
          type="info"
          :disabled="!selectIds.length || disabledKou"
          @click="onBatchDeduct"
          v-if="is_deduct"
          >批量扣款</el-button
        >
      </template>
    </Filtersearch>
    <div class="totalWrap">
      保险费应扣款：{{ totalData.premiums || "" }} 元 保险实际扣款：{{
        totalData.real_premiums || "-"
      }}
      元
      <span v-if="showCity" class="ml10 showText">
        已扣款城市仓：{{ totalData.logistics_count || "0" }}个 待扣款城市仓：{{
          totalData.logistics_count_no || "0"
        }}
        个
      </span>
    </div>
    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        ref="table"
        v-loading="loadingData"
        :tableData="tableData"
        :is_deduct="is_deduct"
        @submit-form="submitForm"
        @onHandleEdit="onHandleEdit"
        @postAjaxLogisticsPremiumsSettlementAudit="
          postAjaxLogisticsPremiumsSettlementAudit
        "
        @postAjaxLogisticsPremiumsSettlementDeduct="
          postAjaxLogisticsPremiumsSettlementDeduct
        "
        @getSelectId="getSelectId"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 编辑 start -->
    <edit-config-dialog
      ref="editConfig"
      @submit-form="submitForm"
    ></edit-config-dialog>
    <!-- 编辑 end -->
  </section>
</template>
<script>
import editConfigDialog from "./modules/editConfig/index";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";
import Filtersearch from "@/components/Filtersearch.vue";
import {
  CAR_CATEGORY_ENUM,
  VEHICLE_MODEL,
  IS_COMBINED_CAR,
  DEDUCTION_ENUM,
  EXAMINE_ENUM,
} from "./utils/enum/index";
import {
  postLogisticsPremiumsSettlementList,
  postLogisticsPremiumsSettlementTotal,
  postLogisticsPremiumsSettlementAudit,
  postLogisticsPremiumsSettlementDeduct,
} from "@/api/general/cost.js";
import { formatDate } from "@/utils//tools/date.js";
import { postLogisticsPremiumsSettlementExport } from "@/api/export/center.js";

export default {
  name: "city-insurance-entry",
  components: { editConfigDialog, TableList, Filtersearch },
  data() {
    return {
      CAR_CATEGORY_ENUM,
      VEHICLE_MODEL,
      IS_COMBINED_CAR,
      Filtersearchlist: [
        {
          type: "select",
          name: "集配中心",
          clearable: true,
          model: "logistic_business_id",
          placeholder: "请选择集配中心",
          selectoptionname: "logisticsList",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "城市仓",
          clearable: true,
          filterable: true,
          multiple: true,
          model: "logistics_id",
          placeholder: "请选择城市仓",
          label: "name",
          value: "id",
          selectoptionname: "cityList",
          selectoption: [],
        },
        {
          type: "select",
          name: "车型",
          clearable: true,
          model: "vehicle_model_name",
          placeholder: "车型",
          selectoptionname: "promotion_tag_list",
          label: "label",
          value: "value",
          selectoption: VEHICLE_MODEL,
        },
        {
          type: "select",
          name: "是否合车",
          clearable: true,
          model: "is_merge",
          placeholder: "是否合车",
          label: "label",
          value: "value",
          selectoption: IS_COMBINED_CAR,
        },
        {
          type: "input",
          name: "车牌(车头)",
          clearable: true,
          model: "front_license_plates_no",
          placeholder: "车牌(车头)",
        },
        {
          type: "select",
          name: "车类别",
          clearable: true,
          model: "vehicle_model_type_name",
          placeholder: "车类别",
          label: "label",
          value: "value",
          selectoption: CAR_CATEGORY_ENUM,
        },
        {
          type: "daterange",
          name: "发车日期",
          clearable: true,
          model: "time",
          startmodel: "start_date",
          endmodel: "end_date",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
        {
          type: "select",
          name: "是否平台调车",
          clearable: true,
          model: "is_platform_dispatch",
          placeholder: "是否平台调车",
          label: "label",
          value: "value",
          selectoption: IS_COMBINED_CAR,
        },
        // {
        //   type: "select",
        //   name: "是否平台装卸",
        //   clearable: true,
        //   model: "is_platform_fleet",
        //   placeholder: "是否平台装卸",
        //   label: "label",
        //   value: "value",
        //   selectoption: IS_COMBINED_CAR,
        // },
        {
          type: "select",
          name: "扣款状态",
          clearable: true,
          model: "is_deduct",
          placeholder: "扣款状态",
          label: "label",
          value: "value",
          selectoption: DEDUCTION_ENUM,
        },
        {
          type: "select",
          name: "审核状态",
          clearable: true,
          model: "is_audit",
          placeholder: "审核状态",
          label: "label",
          value: "value",
          selectoption: EXAMINE_ENUM,
        },
      ],
      loadingCityStore: false, // 城市仓加载loading
      cityStoreList: [],
      total: 0,
      loadingData: false,
      formData: {
        logistic_business_id: "",
        logistics_id: [],
        vehicle_model_name: "",
        is_merge: "",
        front_license_plates_no: "",
        vehicle_model_type_name: "",
        time: [
          formatDate(new Date().getTime() - 3600 * 1000 * 24),
          formatDate(),
        ],
        start_date: formatDate(new Date().getTime() - 3600 * 1000 * 24),
        end_date: formatDate(),
        is_platform_dispatch: "",
        // is_platform_fleet: "",
        is_audit: "",
        is_deduct: "",
        page: 1,
        pageSize: 50,
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
      logisticsList: [], // 集配中心列表
      cityList: [], // 城市仓列表
      selectIds: [], // 批量操作
      totalData: {}, // 总计数据
      showCity: false,
      disabledExport: false,
      is_deduct: false, // 扣款权限
      disabledAudit: true, // 批量操作
      disabledKou: false, // 批量扣款
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    // 选中id 赋值
    getSelectId(e, e1, e2) {
      console.log(e, e1, e2, "22");
      this.selectIds = e;
      this.disabledAudit = e1;
      this.disabledKou = e2;
      console.log(e, this.selectIds, "e1211");
    },
    // 批量审核
    onBatchAudit() {
      this.$confirm("确定批量审核么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          this.postAjaxLogisticsPremiumsSettlementAudit(
            this.selectIds,
            "batch"
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消批量审核",
          });
        });
    },
    // 批量扣款
    onBatchDeduct() {
      this.$confirm("确定批量扣款么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          this.postAjaxLogisticsPremiumsSettlementDeduct(
            this.selectIds,
            "batch"
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消批量扣款",
          });
        });
    },
    // 审核
    async postAjaxLogisticsPremiumsSettlementAudit(ids, type) {
      try {
        const res = await postLogisticsPremiumsSettlementAudit({
          logistics_premiums_ids: ids,
        });
        this.$message({
          type: "success",
          message: "审核成功",
        });
        this.postAjaxLogisticsPremiumsSettlementList();
        this.postAjaxLogisticsPremiumsSettlementTotal();
        if (type == "batch") {
          this.$refs.table.clearSelect();
        }
      } catch (error) {
        console.log(error, "postLogisticsPremiumsSettlementAudit");
      }
    },
    // 批量扣款
    async postAjaxLogisticsPremiumsSettlementDeduct(ids, type) {
      try {
        const res = await postLogisticsPremiumsSettlementDeduct({
          logistics_premiums_ids: ids,
        });
        console.log(res, "res==");
        this.$message({
          type: "success",
          message: "扣款成功",
        });
        if (type == "batch") {
          this.$refs.table.clearSelect();
        }
        this.postAjaxLogisticsPremiumsSettlementList();
        this.postAjaxLogisticsPremiumsSettlementTotal();
      } catch (error) {
        console.log(error, "postLogisticsPremiumsSettlementDeduct");
      }
    },
    // 获取城市仓列表
    async citylist() {
      try {
        const res = await this.$api.general.logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        });
        console.log(res, "res");
        this.cityList = res.data.data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "cityList"
          ) {
            el.selectoption = [
              { area_code: "", name: "全部" },
              ...res.data.data,
            ];
          }
        });
      } catch (error) {
        console.log(error, "logisticsList");
      }
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList({
          is_online: 1, // 启用中
        });
        this.logisticsList = data;
        this.Filtersearchlist.forEach((el) => {
          if (
            el.type == "select" &&
            el.selectoptionname &&
            el.selectoptionname == "logisticsList"
          ) {
            el.selectoption = [{ area_code: "", name: "全部" }, ...data];
          }
        });
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxLogisticsPremiumsSettlementList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxLogisticsPremiumsSettlementList();
    },
    /**
     * 查询
     */
    onHandleSearch(e) {
      this.formData = e;
      this.formData.page = 1;
      this.postAjaxLogisticsPremiumsSettlementList();
      this.postAjaxLogisticsPremiumsSettlementTotal();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.getAjaxLogisticsList();
      this.citylist();
      this.postAjaxLogisticsPremiumsSettlementList();
      this.postAjaxLogisticsPremiumsSettlementTotal();
    },
    /**
     * 列表数据
     */
    async postAjaxLogisticsPremiumsSettlementList() {
      this.loadingData = true;
      try {
        const res = await postLogisticsPremiumsSettlementList(this.formData);
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.is_deduct = res.data.is_deduct;
      } catch (error) {
        console.log(error, "postLogisticsPremiumsSettlementList");
      } finally {
        this.loadingData = false;
      }
    },
    // 保险成本汇总
    async postAjaxLogisticsPremiumsSettlementTotal() {
      try {
        const res = await postLogisticsPremiumsSettlementTotal({
          logistic_business_id: this.formData.logistic_business_id,
          start_date: this.formData.start_date,
          end_date: this.formData.end_date,
        });
        this.totalData = res.data;
        // 发车日期为当前日期 才显示仓的统计
        if (
          this.formData.start_date ==
          formatDate(new Date().getTime() - 3600 * 1000 * 24)
        ) {
          this.showCity = true;
        } else {
          this.showCity = false;
        }
      } catch (error) {
        console.log(error, "postLogisticsPremiumsSettlementTotal");
      }
    },
    /**
     * 重置数据
     */
    Refresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.postAjaxLogisticsPremiumsSettlementList();
      this.postAjaxLogisticsPremiumsSettlementTotal();
    },
    /**
     * 编辑
     */
    onHandleEdit(e) {
      this.$refs.editConfig.initData(e);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      // this.formData.page = 1;
      this.postAjaxLogisticsPremiumsSettlementList();
      this.postAjaxLogisticsPremiumsSettlementTotal();
    },
    /**
     * 导出逻辑
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postLogisticsPremiumsSettlementExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postLogisticsPremiumsSettlementExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.city-insurance-entry-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .totalWrap {
    margin-top: 10px;
    background: #ffffff;
    padding: 12px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;

    .showText {
      font-size: 16px;
    }
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
