<template>
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-descriptions title="" :column="1">
          <el-descriptions-item label="供货日">
            {{ detail.date || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="2">
          <el-descriptions-item label="城市仓">
            {{ detail.logistics_name || "-" }}
          </el-descriptions-item>
          <el-descriptions-item label="合车城市仓">
            {{ detail.merge_logistics_name || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="车型">
            {{ detail.vehicle_model_name || "-" }}</el-descriptions-item
          >
          <el-descriptions-item label="车牌（车头)">{{
            detail.front_license_plates_no || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="车牌（车尾)">
            {{ detail.after_license_plates_no || "-" }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="线上吨位">
            {{ detail.online_tonnage || "-" }}</el-descriptions-item
          >
          <el-descriptions-item label="自采吨位">{{
            detail.self_purchase_tonnage || "-"
          }}</el-descriptions-item>
          <el-descriptions-item label="总计">{{
            detail.all_tonnage || "-"
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="1">
          <el-descriptions-item label="备注">{{
            detail.remark || "-"
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="3">
          <el-descriptions-item label="保险应收款">
            {{ detail.premiums }}元</el-descriptions-item
          >
          <el-descriptions-item label="保险费实际收款">
            <el-input
              v-model="detail.real_premiums"
              placeholder="请输入"
              @input="(val) => handleInput(val, 'real_premiums')"
            ></el-input
            >元
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" :column="2">
          <el-descriptions-item label="扣款备注">
            <el-input
              v-model="detail.change_desc"
              placeholder="请输入扣款备注"
              type="textarea"
              :rows="3"
              maxlength="128"
              show-word-limit
            ></el-input
          ></el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { postLogisticsPremiumsSettlementChange } from "@/api/general/cost.js";

export default {
  name: "editConfigDialog",
  data() {
    return {
      loading: false,
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      detail: {},
      // change_desc: "",
    };
  },
  created() {},
  methods: {
    handleInput(value, e) {
      console.log(value, e, "22");
      let dat =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      this.detail[e] = dat;
    },
    /**
     *初始化数据
     */
    initData(e) {
      console.log(e, "e===");
      this.detail = JSON.parse(JSON.stringify(e));
      // this.change_desc = "";
      this.dialogFormVisible = true;
    },
    async submitFrom() {
      if (!this.detail.change_desc) {
        this.$message.error("请填写扣款备注");
        return;
      }
      this.submitLoading = true;
      try {
        const res = await postLogisticsPremiumsSettlementChange({
          change_desc: this.detail.change_desc,
          logistics_premiums_id: this.detail.id,
          real_premiums: this.detail.real_premiums,
        });
        this.$message.success("编辑成功");
        this.$emit("submit-form");
        this.dialogFormVisible = false;
      } catch (error) {
        console.log(error, "postLogisticsPremiumsSettlementChange");
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;
}

/deep/ .custom-select-dropdown {
  .el-select-dropdown__wrap {
    max-height: 300px !important;
    overflow-y: auto !important;
    box-sizing: border-box;
  }
}

/deep/ .el-descriptions-item__cell {
  line-height: 40px !important;
}
/deep/ .el-input {
  width: 200px !important;
  margin-right: 10px !important;
}
/deep/ .el-textarea {
  width: 300px !important;
}
</style>
