var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      ref: "costReviewTable",
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
      on: { "selection-change": _vm.handleSelectionChange },
    },
    [
      _c("el-table-column", {
        attrs: { type: "selection", width: "55", fixed: "left" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "date",
          align: "center",
          "min-width": "120",
          label: "发车日期",
          "show-overflow-tooltip": "",
          fixed: "left",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistics_name",
          align: "center",
          label: "城市仓",
          fixed: "left",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "is_merge_txt", align: "center", label: "是否合车" },
      }),
      _c("el-table-column", {
        attrs: { prop: "fleet_name", align: "center", label: "队别" },
      }),
      _c("el-table-column", {
        attrs: { prop: "vehicle_model_name", align: "center", label: "车型" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "vehicle_model_type_name",
          align: "center",
          "min-width": "150",
          label: "车类别",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "front_license_plates_no",
          align: "center",
          label: "车牌(车头)",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "after_license_plates_no",
          align: "center",
          label: "车牌(车尾)",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "self_purchase_tonnage",
          align: "center",
          label: "自采吨位",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "online_tonnage", align: "center", label: "线上吨位" },
      }),
      _c("el-table-column", {
        attrs: { prop: "all_tonnage", align: "center", label: "合计吨位" },
      }),
      _c("el-table-column", {
        attrs: { prop: "remark", align: "center", label: "备注" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_platform_dispatch_txt",
          align: "center",
          label: "平台调车",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "premiums", align: "center", label: "保险费应扣款" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "real_premiums",
          align: "center",
          label: "保险费实际扣款",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    class:
                      Number(scope.row.real_premiums) !=
                      Number(scope.row.premiums)
                        ? "textRed"
                        : "",
                    attrs: {
                      disabled:
                        Number(scope.row.real_premiums) ==
                          Number(scope.row.premiums) || !scope.row.change_desc,
                      content: scope.row.change_desc,
                      placement: "top-start",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(" " + _vm._s(scope.row.real_premiums || "0")),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_audit_txt",
          align: "center",
          label: "审核状态",
          fixed: "right",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_deduct_txt",
          align: "center",
          label: "扣款状态",
          fixed: "right",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "tdc_owner_status",
          align: "center",
          label: "操作",
          width: "120",
          fixed: "right",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.is_audit == 0 && scope.row.is_deduct == 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleStatus(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                scope.row.is_audit == 1 && scope.row.is_deduct == 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onHandleStatus(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v("取消审核")]
                        ),
                        _vm.is_deduct
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleStatus(scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v("扣款")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }