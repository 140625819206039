var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "city-insurance-entry-wrap" },
    [
      _c("Filtersearch", {
        ref: "Filtersearchref",
        attrs: { list: _vm.Filtersearchlist, fromdata: _vm.formData },
        on: { Refresh: _vm.Refresh, search: _vm.onHandleSearch },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "warning",
                      icon: "el-icon-upload2",
                      disabled: _vm.disabledExport || !_vm.tableData.length,
                    },
                    on: { click: _vm.onHandleExport },
                  },
                  [_vm._v("导出")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "info",
                      disabled: !_vm.selectIds.length || _vm.disabledAudit,
                    },
                    on: { click: _vm.onBatchAudit },
                  },
                  [_vm._v("批量审核")]
                ),
                _vm.is_deduct
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "info",
                          disabled: !_vm.selectIds.length || _vm.disabledKou,
                        },
                        on: { click: _vm.onBatchDeduct },
                      },
                      [_vm._v("批量扣款")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "totalWrap" }, [
        _vm._v(
          " 保险费应扣款：" +
            _vm._s(_vm.totalData.premiums || "") +
            " 元 保险实际扣款：" +
            _vm._s(_vm.totalData.real_premiums || "-") +
            " 元 "
        ),
        _vm.showCity
          ? _c("span", { staticClass: "ml10 showText" }, [
              _vm._v(
                " 已扣款城市仓：" +
                  _vm._s(_vm.totalData.logistics_count || "0") +
                  "个 待扣款城市仓：" +
                  _vm._s(_vm.totalData.logistics_count_no || "0") +
                  " 个 "
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "section",
        { staticClass: "content-wrap" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            ref: "table",
            attrs: { tableData: _vm.tableData, is_deduct: _vm.is_deduct },
            on: {
              "submit-form": _vm.submitForm,
              onHandleEdit: _vm.onHandleEdit,
              postAjaxLogisticsPremiumsSettlementAudit:
                _vm.postAjaxLogisticsPremiumsSettlementAudit,
              postAjaxLogisticsPremiumsSettlementDeduct:
                _vm.postAjaxLogisticsPremiumsSettlementDeduct,
              getSelectId: _vm.getSelectId,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("edit-config-dialog", {
        ref: "editConfig",
        on: { "submit-form": _vm.submitForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }